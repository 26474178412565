import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { navigate } from "gatsby";
import isEqual from "lodash.isequal";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import AppLayout from "../../components/AppLayout/index";
import ErrorDialog from "../../components/ErrorDialog";
import { useUserService } from "../../services/user";

interface Account {
    vat: string;
    institution: string;
}

const hasChanged = (fields: Account, user: Account | null) => {
    if (!user) {
        return true;
    }

    const { vat, institution } = user;
    return !isEqual(fields, { institution, vat });
};

const AccountManagement = (): JSX.Element => {
    const { t } = useTranslation();
    const { user, updateAccount, error, resetError } = useUserService();
    const [busy, setBusy] = useState(false);
    const [vat, setVat] = useState(user?.vat || "");
    const [institution, setInstitution] = useState(user?.institution || "");
    const changed = hasChanged({ institution, vat }, user);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (changed) {
            setBusy(true);
            await updateAccount(vat, institution);
            setBusy(false);
        }
    };

    return (
        <>
            <AppLayout currentPage={t("Account Management")}>
                <Container maxWidth="sm">
                    <Grid container spacing={2} direction="column">
                        <form
                            noValidate
                            autoComplete="off"
                            onSubmit={handleSubmit}
                        >
                            <Box p={2} width={1}>
                                <TextField
                                    id="institution"
                                    label={t("Institution")}
                                    value={institution}
                                    onChange={event =>
                                        setInstitution(event.target.value)
                                    }
                                />
                            </Box>
                            <Box p={2} width={1}>
                                <TextField
                                    id="vat"
                                    label={t("VAT")}
                                    value={vat}
                                    onChange={event =>
                                        setVat(event.target.value)
                                    }
                                />
                            </Box>
                            <Box p={2} width={1}>
                                <Grid container direction="row" spacing={5}>
                                    <Grid item xs={6}>
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            type="button"
                                            onClick={() =>
                                                navigate("/dashboard")
                                            }
                                            fullWidth
                                        >
                                            {t("Go back")}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            type="submit"
                                            disabled={!changed || busy}
                                            fullWidth
                                        >
                                            {t("Save")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </form>
                    </Grid>
                </Container>
                <ErrorDialog error={error} reset={resetError} />
            </AppLayout>
        </>
    );
};

export default AccountManagement;
